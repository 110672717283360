// This file contains all configurations for RefreshSessionModal
// Notice all times amounts are expressed in seconds preferably

// Session Time Seconds must be equal to configured session expiration time at backend
const MODAL_COUNTDOWN_SECONDS = 30;
const PUBLIC_PATHNAMES = ['/accounts/login/', '/accounts/logout/', '/accounts/password-reset/'];
const LOGOUT_PATH = PUBLIC_PATHNAMES[1];

export {
  MODAL_COUNTDOWN_SECONDS,
  PUBLIC_PATHNAMES,
  LOGOUT_PATH
};
