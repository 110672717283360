function initializeServicePendings(connectSelects) {
  const $form = $('form.service-pendings-form');
  if ($form.length) {
    // initialize select2 for id_users
    const usersSelect = $form.find('#id_users');
    const tags = usersSelect.hasClass('js-select2-tags');
    usersSelect.select2({
      width: '100%',
      tags,
      theme: 'bootstrap4'
    });

    connectSelects(
      ['#id_users', '#id_statuses'],
      '/services/pendings/statuses/'
    );

    connectSelects(
      ['#id_users', '#id_statuses', '#id_sites'],
      '/services/pendings/sites/'
    );

    connectSelects(
      ['#id_users', '#id_statuses', '#id_sites', '#id_service'],
      '/services/pendings/services/'
    );
  }
}

export default initializeServicePendings;
