export const mapApiLanguage = (language) => {
  const LANGUAGES_MAP = {
    english_name: 'englishName',
    long_name: 'longName',
    short_name: 'shortName'
  };

  const mappedLanguage = LANGUAGES_MAP[language];

  if (mappedLanguage === null) {
    return 'Language not available';
  }

  return mappedLanguage;
};
