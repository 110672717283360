import {
  modelByContentType, formatPrefilter, formatSorting, makeQueryString
} from '../utils/utils';

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const getDataTableEditorResources = async () => fetch('/api/v1/data-view-editor-resources/', {
  'Content-Type': 'application/json'
}).then((data) => {
  if (data.ok) {
    return data.json();
  }
  // eslint-disable-next-line no-console
  console.error(`Error making request to ${data.url}:`);
  // eslint-disable-next-line no-console
  console.error(`${data.status} - ${data.statusText}`);
  return null;
});

export const getViewsByContentType = async (contentType, search = '') => fetch(`/api/v1/data-views/by-model-name/${contentType}/?search=${search}`, {
  'Content-Type': 'application/json'
}).then((data) => {
  if (data.ok) {
    return data.json();
  }
  // eslint-disable-next-line no-console
  console.error(`Error making request to ${data.url}:`);
  // eslint-disable-next-line no-console
  console.error(`${data.status} - ${data.statusText}`);
  return null;
});

export const getColumnsByContentType = async (contentType) => fetch(`/api/v1/nomenclator-content-types/${contentType}/`, {
  'Content-Type': 'application/json'
}).then((data) => {
  if (data.ok) {
    return data.json();
  }
  // eslint-disable-next-line no-console
  console.error(`Error making request to ${data.url}:`);
  // eslint-disable-next-line no-console
  console.error(`${data.status} - ${data.statusText}`);
  return null;
});

export const createView = async (contentType, view) => fetch(`/api/v1/data-views/by-model-name/${contentType}/`, {
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken')
  },
  method: 'POST',
  body: JSON.stringify(view)
}).then(async (data) => {
  if (data.ok) {
    return data.json();
  }
  const error = await data.json();
  const keys = Object.keys(error);
  const { message } = error[keys[0]][0];

  if (message) {
    return { error: message };
  }
  return { error: gettext('An error has ocurred') };
});

export const updateView = async (id, view, isDefault = false) => fetch(`/api/v1/data-views/${id}/`, {
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken')
  },
  method: 'PUT',
  body: JSON.stringify({ ...view, default: isDefault })
}).then(async (data) => {
  if (data.ok) {
    return data.json();
  }
  const error = await data.json();
  const keys = Object.keys(error);
  const { code } = error[keys[0]][0];

  if (code === 'unique_together') {
    return { error: gettext('This name already exists') };
  }
  return { error: gettext('An error has ocurred') };
});

export const deleteView = async (id) => fetch(`/api/v1/data-views/${id}/`, {
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken')
  },
  method: 'DELETE'
}).then(async (data) => data.ok);

export const getDataTableByContentType = async (
  signal,
  contentType,
  pageSize,
  page,
  viewName,
  search,
  prefilter,
  sorting
) => fetch(`/api/v1/${modelByContentType[contentType].getDataEndPoint}/?page_size=${pageSize}&page=${page}&view_name=${viewName}&${search ? `search=${encodeURIComponent(search)}` : ''}&${formatPrefilter(prefilter)}&${formatSorting(sorting)}`, {
  'Content-Type': 'application/json',
  signal
}).then((data) => {
  if (data.ok) {
    return data.json();
  }
  // eslint-disable-next-line no-console
  console.error(`Error making request to ${data.url}:`);
  // eslint-disable-next-line no-console
  console.error(`${data.status} - ${data.statusText}`);
  return null;
});

export const getPrefilterOptions = async (contentType, preFilters = []) => {
  const queryString = makeQueryString(preFilters.map((value) => ['pre_filter', value]));
  const data = await fetch(`/api/v1/${modelByContentType[contentType].getDataEndPoint}/${queryString.length > 0 ? `?${queryString}` : ''}`, {
    method: 'OPTIONS'
  });

  if (data.ok) {
    const response = await data.json();
    return response;
  }

  console.error(`Error making request to ${data.url}`);
  console.error(`${data.status} - ${data.statusText}`);

  return null;
};
