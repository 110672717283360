import React from 'react';
import ReactDOM from 'react-dom';

import TableView from './TableView';

const element = document.querySelector('.react-data-table');

if (element) {
  ReactDOM.render(
    React.createElement(TableView, {
      contentType: element.dataset.contentType,
      user: Number(element.dataset.user),
      language: element.dataset.language,
      isAdmin: element.dataset.isAdmin === 'True',
      prefilter: element.dataset.prefilter,
      initialOrder: element.dataset.initialOrder
    }),
    element
  );
}
