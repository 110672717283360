$(() => {
  $('.datetimepicker-input').each((i, item) => {
    const $this = $(item);
    $this.data('target', `#${$this.prop('id')}`);

    const initConfig = {
      date: moment($this.val(), 'DD/MM/YYYY'),
      format: $this.data('format'),
      locale: 'es'
    };
    if ($this.data('format')) {
      initConfig.date = moment($this.val(), $this.data('format'));
    }
    $this.datetimepicker(initConfig);
  });
});
