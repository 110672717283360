import React from 'react';
import ReactDOM from 'react-dom';

import PopoverIconComponent from './PopoverIconComponent';

const elements = document.querySelectorAll('.react-popover-icon');
const renderPopoverComponent = (element) => {
  ReactDOM.render(
    React.createElement(PopoverIconComponent, {
      icon: element.dataset.icon,
      popoverText: element.dataset.popoverText,
      popoverTitle: element.dataset.popoverTitle
    }),
    element
  );
};

if (elements) {
  elements.forEach(renderPopoverComponent);
}

export { renderPopoverComponent };
