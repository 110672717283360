import { number, func } from 'prop-types';
import React from 'react';

export default function DataTablePaginator({
  currentPage,
  totalCount,
  itemsPerPage,
  handleChangeCurrentPage
}) {
  const renderPages = () => {
    const numPages = Math.ceil(totalCount / itemsPerPage);
    const pages = [];
    for (let i = 1; i <= numPages; i += 1) {
      if (i === currentPage) {
        pages.push(
          <li key={i} className="paginate_button page-item active">
            <button onClick={() => handleChangeCurrentPage(i)} type="button" className="page-link">
              {i}
            </button>
          </li>
        );
      } else if (currentPage < 5) {
        if (i <= 5 || i === numPages) {
          pages.push(
            <li key={i} className="paginate_button page-item">
              <button onClick={() => handleChangeCurrentPage(i)} type="button" className="page-link">
                {i}
              </button>
            </li>
          );
        } else if (i === 6) {
          pages.push(
            <li key={i} className="paginate_button page-item disabled">
              <div className="page-link">
                ...
              </div>
            </li>
          );
        }
      } else if (currentPage > (numPages - 4)) {
        if (i >= (numPages - 4) || i === 1) {
          pages.push(
            <li key={i} className="paginate_button page-item">
              <button onClick={() => handleChangeCurrentPage(i)} type="button" className="page-link">
                {i}
              </button>
            </li>
          );
        } else if (i === (numPages - 5)) {
          pages.push(
            <li key={i} className="paginate_button page-item disabled">
              <div className="page-link">
                ...
              </div>
            </li>
          );
        }
      } else if (currentPage <= (numPages - 4) && currentPage >= 5) {
        if (i === 1 || i === numPages || i === (currentPage - 1) || i === (currentPage + 1)) {
          pages.push(
            <li key={i} className="paginate_button page-item">
              <button onClick={() => handleChangeCurrentPage(i)} type="button" className="page-link">
                {i}
              </button>
            </li>
          );
        } else if (i === (currentPage - 2) || i === (currentPage + 2)) {
          pages.push(
            <li key={i} className="paginate_button page-item disabled">
              <div className="page-link">
                ...
              </div>
            </li>
          );
        }
      }
    }
    return pages;
  };

  return (
    <div className="d-flex justify-content-between datatable-footer">
      <div>
        <div className="dataTables_info text-wrap">
          {`${gettext('Showing records from')}
            ${((currentPage - 1) * itemsPerPage) + 1}
            ${gettext('to')} ${Math.min((currentPage) * (itemsPerPage), (totalCount))}
            ${gettext('of a total of')} ${totalCount} ${gettext('records')}`}
        </div>
      </div>
      <div>
        <div className="dataTables_paginate paging_simple_numbers">
          <ul className="pagination">
            <li className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
              <button type="button" className="page-link" onClick={() => handleChangeCurrentPage(currentPage - 1)}>
                {gettext('Previous')}
              </button>
            </li>
            {renderPages().map((page) => page)}
            <li className={`paginate_button page-item previous ${currentPage === Math.ceil(totalCount / itemsPerPage) ? 'disabled' : ''}`}>
              <button type="button" className="page-link" onClick={() => handleChangeCurrentPage(currentPage + 1)}>
                {gettext('Next')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

DataTablePaginator.propTypes = {
  currentPage: number.isRequired,
  totalCount: number,
  itemsPerPage: number.isRequired,
  handleChangeCurrentPage: func.isRequired
};

DataTablePaginator.defaultProps = {
  totalCount: 0
};
