import React, { useContext } from 'react';
import {
  arrayOf, number, string, bool, shape
} from 'prop-types';
import PopoverIcon from '../../PopoverIcon/PopoverIconComponent';
import { userContentTypeContext } from '../context/context';
import { nomenclatorLabel, LANGUAGES_CHOICES } from '../utils/utils';

export default function ViewDetails({ view }) {
  const {
    language,
    dataTableEditorResources
  } = useContext(userContentTypeContext);

  return (
    <div>
      <strong className="mb-3 d-block">{gettext('View details')}</strong>
      <div className="d-flex mb-4">
        <p className="mb-0">{`${nomenclatorLabel('386_slctor_view_des', dataTableEditorResources, language)}:`}</p>
        <p className="ml-5 mb-0">{view.description}</p>
      </div>
      <strong className="mb-3 d-block">{gettext('Entries select')}</strong>
      <div className="d-flex flex-column">
        <p>{nomenclatorLabel('388_slctor_vw_per', dataTableEditorResources, language)}</p>
        <div className="transfer-list">
          {view.columns.map((col) => (
            <div key={col.id} className="py-3 border-bottom d-flex justify-content-between">
              <div className="d-flex">
                <PopoverIcon icon="fa-info-circle" popoverText={col.observations} popoverTitle={gettext('Column observations')} />
                <p className="ml-2 mb-0">
                  {language === LANGUAGES_CHOICES.NOMENCLATOR.value && (col.shortName || gettext('Language not available'))}
                  {language === LANGUAGES_CHOICES.ENGLISH.value && (col.englishName || gettext('Language not available'))}
                  {language === LANGUAGES_CHOICES.SPANISH.value && (col.longName || gettext('Language not available'))}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ViewDetails.propTypes = {
  view: shape({
    columns: arrayOf(shape({
      id: number.isRequired,
      shortName: string.isRequired,
      observations: string.isRequired
    })),
    default: bool.isRequired,
    description: string.isRequired,
    displayName: string.isRequired,
    id: number.isRequired,
    user: number
  }).isRequired
};
