import React, { useContext, useState, useEffect } from 'react';
import {
  string,
  arrayOf,
  shape,
  func,
  bool
} from 'prop-types';
import ButtonIcon from './ButtonIcon';
import PopoverIcon from '../../PopoverIcon/PopoverIconComponent';
import { LANGUAGES_CHOICES, nomenclatorLabel } from '../utils/utils';
import { userContentTypeContext } from '../context/context';
import { mapApiLanguage } from '../utils/mapApiLanguage';

const NO_OPTIONS_MESSAGE = {
  englishName: 'No options available',
  longName: 'No hay opciones disponibles',
  shortName: 'No hay opciones disponibles'
};

export default function TransferList({
  columnsAvailable,
  columnsTransfered,
  handleChangeSelectedColumns,
  isError,
  language
}) {
  const [columns, setColumns] = useState([]);
  const [searchFilteredColumns, setSearchFilteredColumns] = useState(columns || []);
  const [searchFilter, setSearchFilter] = useState('');

  const {
    dataTableEditorResources
  } = useContext(userContentTypeContext);

  useEffect(() => {
    const columnsFiltered = columnsAvailable
      .filter((column) => !columnsTransfered
        .find((columnTransfered) => columnTransfered.shortName === column.shortName));
    setColumns(columnsFiltered);
  }, [columnsAvailable, columnsTransfered]);

  const handleAddToTransferedList = (columnToTransfer) => {
    const newColumns = columns.filter((column) => column.shortName !== columnToTransfer.shortName);
    const newColumnsTransfered = [...columnsTransfered, columnToTransfer];
    setColumns(newColumns);
    setSearchFilter('');
    handleChangeSelectedColumns(newColumnsTransfered);
  };

  const handleDeleteFromTransferedList = (columnToTransfer) => {
    const newColumnsTransfered = columnsTransfered
      .filter((column) => column.shortName !== columnToTransfer.shortName);
    const newColumns = [...columns, columnToTransfer];
    setColumns(newColumns);
    handleChangeSelectedColumns(newColumnsTransfered);
  };

  const handleUp = (columnToUp, index) => {
    const newColumns = [...columnsTransfered];
    newColumns.splice(index, 1);
    newColumns.splice(index - 1, 0, columnToUp);
    handleChangeSelectedColumns(newColumns);
  };

  const handleDown = (columnToDown, index) => {
    const newColumns = [...columnsTransfered];
    newColumns.splice(index, 1);
    newColumns.splice(index + 1, 0, columnToDown);
    handleChangeSelectedColumns(newColumns);
  };

  const availableToOrderDown = (col, index, isLast) => {
    if (!col.requiredByDataview && !isLast) {
      return true;
    }

    if (col.requiredByDataview && !isLast) {
      const nextColumn = columnsTransfered[index + 1];
      if (nextColumn?.requiredByDataview) {
        return true;
      }
      return false;
    }

    return false;
  };

  const availableToOrderUp = (col, index, isFirst) => {
    if (!col.requiredByDataview && !isFirst) {
      const previousColumn = columnsTransfered[index - 1];
      if (!previousColumn?.requiredByDataview) {
        return true;
      }
      return false;
    }

    if (col.requiredByDataview && !isFirst) {
      return true;
    }

    return false;
  };

  const handleSearchFilter = (e) => {
    setSearchFilter(e.target.value);
  };

  useEffect(() => {
    const filteredColumns = columns
      .filter((column) => (column[mapApiLanguage(language)]?.toLowerCase() ?? 'Language not available')
        .includes(searchFilter?.toLowerCase()));
    setSearchFilteredColumns(filteredColumns);
  }, [searchFilter, columns, language]);

  const renderColumnRow = (col, index, isTransfered, isFirst, isLast, observations) => (
    <div key={index} className="py-3 border-bottom d-flex justify-content-between">
      <div className="d-flex">
        <PopoverIcon icon="fa-info-circle" popoverText={observations} popoverTitle={gettext('Column observations')} />
        <p className="ml-2 mb-0">
          {language === LANGUAGES_CHOICES.NOMENCLATOR.value && (col.shortName || gettext('Language not available'))}
          {language === LANGUAGES_CHOICES.ENGLISH.value && (col.englishName || gettext('Language not available'))}
          {language === LANGUAGES_CHOICES.SPANISH.value && (col.longName || gettext('Language not available'))}
        </p>
      </div>
      {isTransfered
        ? (
          <div className="text-nowrap">
            {availableToOrderUp(col, index, isFirst) && (
              <ButtonIcon icon="fa-arrow-alt-circle-up" handleClick={() => handleUp(col, index)} />
            )}
            {availableToOrderDown(col, index, isLast) && (
              <ButtonIcon icon="fa-arrow-alt-circle-down" handleClick={() => handleDown(col, index)} />
            )}
            {!col.requiredByDataview && (
              <ButtonIcon icon="fa-minus-square" handleClick={() => handleDeleteFromTransferedList(col)} />
            )}
          </div>
        )
        : <ButtonIcon icon="fa-plus-square" handleClick={() => handleAddToTransferedList(col)} />}
    </div>
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center">
        { /* Table entries */ }
        <div className="mr-2 mb-2 w-50 d-flex align-items-center">
          <p className="mb-0 text-center">{nomenclatorLabel('387_slctor_ent', dataTableEditorResources, language)}</p>
          <input type="text" className="form-control ml-3" onChange={handleSearchFilter} value={searchFilter} />
        </div>
        { /* Custom view entries */ }
        <p className="ml-2 w-50 mb-0">{nomenclatorLabel('388_slctor_vw_per', dataTableEditorResources, language)}</p>
      </div>
      <div className="d-flex">
        <div className="w-50">
          <div className="pr-2 mr-2 transfer-list">
            {!searchFilteredColumns?.length
              ? <p className="mt-3 text-center">{NO_OPTIONS_MESSAGE[mapApiLanguage(language)]}</p>
              : searchFilteredColumns.map((col, index) => (
                renderColumnRow(col, index, false, false, false, col.observations)
              ))}

          </div>
        </div>
        <div className="w-50">
          <div className="pr-2 ml-2 transfer-list">
            {columnsTransfered.map((col, index) => {
              if (index === 0) {
                return renderColumnRow(col, index, true, true, false, col.observations);
              } if (index === columnsTransfered.length - 1) {
                return renderColumnRow(col, index, true, false, true, col.observations);
              }
              return renderColumnRow(col, index, true, false, false, col.observations);
            })}
          </div>
        </div>
      </div>
      {isError && (
        <span className="mt-1 d-block text-danger">{gettext('Please, select at least one column')}</span>
      )}
    </div>
  );
}

TransferList.propTypes = {
  language: string.isRequired,
  columnsTransfered: arrayOf(shape({
    shortName: string.isRequired,
    longName: string.isRequired,
    englishName: string,
    observations: string.isRequired
  })).isRequired,
  columnsAvailable: arrayOf(shape({
    shortName: string.isRequired
  })).isRequired,
  handleChangeSelectedColumns: func.isRequired,
  isError: bool.isRequired
};
