const obtainTabId = () => {
  const { sessionStorage } = window;
  const tabID = sessionStorage.tabID
    && sessionStorage.closedLastTab !== '2'
    ? sessionStorage.tabID
    : sessionStorage.tabID = Math.random();

  sessionStorage.closedLastTab = '2';
  $(window).on('unload beforeunload', () => {
    sessionStorage.closedLastTab = '1';
  });

  return tabID;
};

const createHistory = (breadcrumbs) => {
  const history = [];
  breadcrumbs.each((index, crumb) => {
    history.push($(crumb).html());
  });
  return history;
};

const obtainCurrentPageLink = () => {
  const title = document.title.slice(0, -9);
  const path = document.location.pathname;

  const data = `<a href="${path}">${title}</a>`;

  return data;
};

const getHistory = (tabID) => {
  let $breadcrumbs = $('.breadcrumb-item.replace-with-parent');
  let history = JSON.parse(localStorage.getItem(tabID)) || [];

  if (!$breadcrumbs.length || !history.length) {
    $breadcrumbs = $('.breadcrumb-item');
    history = createHistory($breadcrumbs.slice(0, -1));
  }

  const currentPageLink = obtainCurrentPageLink();

  const previousIndex = history.findIndex((obj) => $(obj).attr('href') === document.location.pathname);

  if (previousIndex === -1) {
    history.push(currentPageLink);
  } else {
    history.splice(previousIndex + 1, history.length - previousIndex);
  }

  localStorage.setItem(tabID, JSON.stringify(history));

  return history.slice(-4);
};

const getHtml = (page, isLast) => {
  let current = '';
  let content = page;

  if (isLast) {
    current = " active' aria-current='page";

    content = document.title.slice(0, -9);
  }

  const item = `<li class='breadcrumb-item${current}'> ${content} </li>`;
  return item;
};

const showBreadcrumbs = (history) => {
  const replaceable = $('.breadcrumb-item.replace-with-parent');
  if (replaceable.length && history.length) {
    $('.nav-bread ol').empty();
    let html = '';
    history.forEach((page, index) => {
      html += getHtml(page, history.length - 1 === index);
    });
    $('.nav-bread ol').append(html);
  }
};

const manageBreadcrumbs = () => {
  const tabID = obtainTabId();

  showBreadcrumbs(getHistory(tabID));

  $(document).ready(() => {
    $('.nav-item').click(() => {
      const newHistory = [];
      localStorage.setItem(tabID, JSON.stringify(newHistory));
    });
  });
};

$(() => {
  manageBreadcrumbs();
});
