import React from 'react';
import ReactDOM from 'react-dom';

import NetworkTree from './NetworksTree';

const element = document.querySelector('.react-networks-tree');

if (element) {
  let companyNetworkTypes;
  if (element.dataset.companyNetworkTypes) {
    companyNetworkTypes = JSON.parse(element.dataset.companyNetworkTypes);
  } else {
    companyNetworkTypes = [];
  }
  ReactDOM.render(
    React.createElement(NetworkTree, {
      siteId: Number(element.dataset.siteId),
      companyId: Number(element.dataset.companyId),
      serviceId: Number(element.dataset.serviceId),
      resourceId: Number(element.dataset.resourceId),
      companyNetworkTypes,
      assignMode: element.dataset.assignMode === 'false' ? false : !!element.dataset.assignMode,
      showAssignNew: element.dataset.showAssignNew === 'false' ? false : !!element.dataset.showAssignNew
    }),
    element
  );
}
