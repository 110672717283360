import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import DataTable from 'react-data-table-component';
import PopoverIcon from '../../PopoverIcon/PopoverIconComponent';

const languageSelectorMap = {
  long_name: 'longName',
  short_name: 'shortName',
  english_name: 'englishName'
};

export default function ExpandableSubtable({ subTable, language }) {
  // eslint-disable-next-line no-underscore-dangle
  const { header: tableHeaders, rows: tableRows, _meta: metadata } = subTable;
  const subTableColumns = tableHeaders.map((headerCode) => {
    const currentLanguage = languageSelectorMap[language];
    const translatedName = metadata?.header[headerCode]?.[currentLanguage] ?? headerCode;
    const observations = metadata?.header[headerCode]?.observations || '';

    return {
      name: (
        <div className="d-flex align-items-start">
          <span className="px-5 mx-auto my-auto">{translatedName}</span>
          <PopoverIcon icon="fa-info-circle" popoverText={observations} popoverTitle={gettext('Column observations')} />
        </div>
      ),
      selector: (row) => row[headerCode].value,
      cell: (row) => {
        if (row[headerCode]?.url) {
          return <a className="px-5 mx-auto d-inline-block text-center" href={row[headerCode].url}>{row[headerCode].value}</a>;
        }

        return <span className="px-5 mx-auto d-inline-block text-center">{row[headerCode].value}</span>;
      }
    };
  });

  // eslint-disable-next-line no-underscore-dangle
  const subTableData = tableRows?.map((row) => {
    const formattedRow = {};

    if (!row.length) {
      formattedRow.disabled = true;
    }

    Object.entries(row).forEach(([key, data]) => {
      if (key in formattedRow) {
        return;
      }

      formattedRow[key] = {
        value: data.value,
        url: data.url
      };
    });

    return formattedRow;
  });

  return (
    <div className="my-2 pr-3">
      <DataTable columns={subTableColumns} data={subTableData} />
    </div>
  );
}

ExpandableSubtable.propTypes = {
  subTable: shape({
    data: shape({
      _hidden_tables: shape({
        header: arrayOf(string),
        rows: arrayOf(shape({
          value: string,
          url: string
        }))
      })
    })
  }).isRequired,

  language: string.isRequired
};
