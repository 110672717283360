const select2UsedSelectors = [];

function connectSelects(selectors, url, lookupNames) {
  const childSelector = selectors[selectors.length - 1];
  const $childSelect = $(childSelector);
  const parentSelectors = selectors.slice(0, selectors.length - 1);
  const tags = $childSelect.hasClass('js-select2-tags');

  const lookups = lookupNames || [];

  if (!$childSelect.length) {
    return;
  }

  for (let i = 0; i < parentSelectors.length; i += 1) {
    const $parent = $(parentSelectors[i]);

    if ($parent.length === 0) {
      return;
    }

    $parent.change(() => {
      $childSelect.val(null).trigger('change');
    });

    if (lookups.length <= i) {
      lookups.push($parent.attr('name'));
    }
  }
  $childSelect.select2({
    width: '100%',
    ajax: {
      url,
      dataType: 'json',
      delay: 250,
      data: (params) => {
        const data = {};

        parentSelectors.forEach((parentSelector, index) => {
          let value = $(parentSelector).val();
          if (value.join) {
            value = value.join(',');
          }
          data[lookups[index]] = value;
        });

        return {
          ...data,
          q: params.term
        };
      },
      processResults: (data) => ({ results: data }),

      cache: true
    },
    tags,
    theme: 'bootstrap4'
  });

  // mark selects as already used for select2
  select2UsedSelectors.push(childSelector);
}

$(() => {
  // Declare all region and commune input pairs
  const communeRegionPairs = [
    ['#id_region', '#id_commune'],
    ['#id_legal_address_region', '#id_legal_address_commune'],
    ['#id_commercial_address_region', '#id_commercial_address_commune']
  ];

  /**
  * Fill communes by region selected
  * @param {Object} e
  */
  // for each commune - region pair
  communeRegionPairs.forEach((value) => {
    connectSelects(value, '/regions/communes/search/', ['region_id']);
  });

  // Connect the user list with company
  connectSelects(
    ['#id_role_category', '#id_role'],
    '/accounts/role-search/',
    ['roleCategoryId']
  );

  // Declare service intuitive descrption and service
  // intuitive descrption type input pairs
  const intuitiveDescriptionTypePairs = [
    ['#id_intuitive_description_type', '#id_intuitive_description']
  ];
  // for each intuitive description - intuitive description type pair
  intuitiveDescriptionTypePairs.forEach((value) => {
    connectSelects(value, '/services/intuitive_descriptions/search/', ['typeId']);
  });

  if ($('#form-invoicedescription').length) {
    $('#id_kind').change((e) => {
      /// Invoice type 3 selectd
      const $serviceSelect = $('#id_services');
      if ($(e.target).val() === '3') {
        $serviceSelect.parent().hide();
        $serviceSelect.val(null).trigger('change');
      } else {
        $serviceSelect.parent().show();
      }
    });
    $('#id_kind').change();

    connectSelects(['#id_company', '#id_sites'], '/sites/search/', ['companyId']);
    connectSelects(['#id_sites', '#id_services'], '/services/search/', ['siteIds']);
  }

  connectSelects(
    ['#id_link_type', '#id_provider_company', '#id_link_technology'],
    '/resources/carrier/link-technologies/search/',
    ['link_type_id']
  );

  // Declare all service code input pairs
  const serviceCodePairs = [
    ['#id_destination_site_1', '#id_provider_company', '#id_service_code_1'],
    ['#id_destination_site_2', '#id_provider_company', '#id_service_code_2']
  ];

  serviceCodePairs.forEach((value) => {
    connectSelects(
      value,
      '/resources/carrier/service-codes/search/',
      ['datacenter_id']
    );
  });

  setTimeout(() => {
    $('select').not('.js-not-select2').not(select2UsedSelectors.join()).each((i, select) => {
      const $select = $(select);
      const tags = $select.hasClass('js-select2-tags');
      $select.select2({
        theme: 'bootstrap4',
        tags
      });
    });
  }, 100);
});

export default connectSelects;
