import React from 'react';
import { string, func } from 'prop-types';

export default function ButtonIcon({
  icon, handleClick
}) {
  return (
    <button onClick={handleClick} className="bg-transparent border-0" type="button">
      <i className={`fas ${icon}`} />
    </button>
  );
}

ButtonIcon.propTypes = {
  icon: string.isRequired,
  handleClick: func.isRequired
};
