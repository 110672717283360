// This file stores all time and dates transformation handlers

/**
 * Transforms an amount of seconds into miliseconds
 * @param {number} seconds
 * @returns {number} miliseconds amount
 */
const secondsToMilliseconds = (seconds) => seconds * 1000;

/**
 * Transforms an amount of minutes into seconds
 * @param {number} minutes
 * @returns {number} seconds amount
 */
const minutesToSeconds = (minutes) => minutes * 60;

export { secondsToMilliseconds, minutesToSeconds };
