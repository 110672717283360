import React, { useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';

export default function PopoverIconComponent({
  icon, popoverText, popoverTitle
}) {
  const refButton = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (refButton.current) {
      $(refButton.current).popover({
        trigger: 'focus',
        boundary: 'window',
        sanitize: false
      });

      $(refButton.current).on('hidden.bs.popover', () => {
        setPopoverOpen(false);
      });

      $(refButton.current).on('shown.bs.popover', () => {
        setPopoverOpen(true);
      });
    }
  }, [refButton]);

  const handleClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    if (popoverOpen) {
      $(refButton.current).popover('hide');
    } else {
      $(refButton.current).popover('show');
    }
  };

  return (
    <button
      onClick={handleClick}
      data-toggle="button-popover"
      data-original-title={popoverTitle ?? gettext('Column observations')}
      data-content={popoverText}
      data-html="true"
      type="button"
      className="bg-transparent border-0"
      ref={refButton}
    >
      <i
        className={`fas ${icon}`}
      />
    </button>
  );
}

PopoverIconComponent.propTypes = {
  icon: string.isRequired,
  popoverText: string.isRequired,
  popoverTitle: string.isRequired
};
