import React from 'react';
import PropTypes from 'prop-types';

const styleClass = {
  available: 'success',
  unavailable: 'danger',
  partial: 'warning',
  withoutService: 'danger'
};

const translations = {
  available: 'Disponible',
  unavailable: 'No disponible',
  partial: 'Parcial',
  withoutService: 'Sin Servicio'
};

export default function NetworkStatusBadge({ status }) {
  return <span className={`badge badge-${styleClass[status]}`}>{translations[status]}</span>;
}

NetworkStatusBadge.propTypes = {
  status: PropTypes.string.isRequired
};
