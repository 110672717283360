import { useEffect } from 'react';

class NetworksApi {
  // Generic urls
  static networkUrl = '/api/v1/networks/{cidr}/';
  static allNetworksUrl = '/api/v1/networks/';
  // Site urls
  static siteNetworksUrl = '/api/v1/networks/site/{siteId}/';

  // Company urls
  static companyNetworksUrl = '/api/v1/networks/company/{companyId}/';
  static companyAssignableNetworksUrl = this.allNetworksUrl;
  static assignCompanyNetworkUrl = '/api/v1/networks/{cidr}/';

  // Service urls
  static serviceNetworksUrl = '/api/v1/networks/service/{serviceId}/';
  static serviceAssignableNetworksUrl = '/api/v1/networks/service/{serviceId}/assignable/';
  static assignServiceNetworkUrl = '/api/v1/networks/{cidr}/';
  // Resource urls
  static resourceNetworksUrl = '/api/v1/networks/physical-resource/{resourceId}/';
  static resourceAssignableNetworksUrl = '/api/v1/networks/physical-resource/{resourceId}/assignable';
  static assignResourceNetworkUrl = '/api/v1/networks/{cidr}/';
  // Filter urls
  static networkTypesUrl = '/api/v1/network-types/?mode={mode}';
  static networkGroupsUrl = '/api/v1/network-groups/?nomenclator_entry={typeId}';

  /**
   * Required to get the csrf tokens for post/patch requests.
   * @param {string} name Cookie name.
   */
  static getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  /**
   * Makes a request using fetch. It extracts json from answers and handles
   * errors in the request. Automatically injects csrf token if needed.
   * @param {string} url API endpoint url.
   * @param {object} options Options for fetch().
   */
  static async makeRequest(url, options) {
    const requestOptions = { ...(options || {}) };
    requestOptions.headers = {
      ...requestOptions.headers,
      'X-CSRFToken': this.getCookie('csrftoken'),
      'Content-Type': 'application/json'
    };
    return fetch(url, requestOptions).then((data) => {
      if (data.ok) {
        return data.json();
      }
      // eslint-disable-next-line no-console
      console.error(`Error making request to ${data.url}:`);
      // eslint-disable-next-line no-console
      console.error(`${data.status} - ${data.statusText}`);
      return null;
    });
  }

  /**
   * Get the related network roots to show or assign.
   * Only one of {siteId, serviceId, resourceId} should be set at once.
   * @param {number} networkGroupId Required.
   * @param {number?} siteId Required if showing networks for a site.
   * @param {number?} serviceId Required if showing or assigning networks to a service.
   * @param {number?} resourceId Required if showing or assigning networks to a resource.
   * @param {boolean} isAssignMode True to get roots of all assignable networks,
   * false to get only the roots of the assigned networks.
   */
  static async getRelatedNetworks(
    networkGroupId, companyId, siteId, serviceId, resourceId, isAssignMode
  ) {
    let url = this.allNetworksUrl;

    if (siteId) {
      url = this.siteNetworksUrl.replace('{siteId}', siteId);
    } else if (companyId) {
      if (isAssignMode) {
        url = this.companyAssignableNetworksUrl
          .replace('{networkGroupId}', networkGroupId);
      } else {
        url = this.companyNetworksUrl.replace('{companyId}', companyId);
      }
    } else if (serviceId) {
      if (isAssignMode) {
        url = this.serviceAssignableNetworksUrl
          .replace('{serviceId}', serviceId);
      } else {
        url = this.serviceNetworksUrl.replace('{serviceId}', serviceId);
      }
    } else if (resourceId) {
      if (isAssignMode) {
        url = this.resourceAssignableNetworksUrl.replace('{resourceId}', resourceId);
      } else {
        url = this.resourceNetworksUrl.replace('{resourceId}', resourceId);
      }
    }

    url += `?network_sub_group=${networkGroupId}`;

    return this.makeRequest(url)
      .then((data) => data.map(((o) => `${o.startIp}/${o.prefix}`)));
  }

  /**
   * Get the hydrated version of a subnet identified by a CIDR string.
   * @param {string} cidr Network (or ip) identified as 'aaa.bbb.ccc.ddd/nn'.
   */
  static async getSubnet(cidr) {
    return this.makeRequest(this.networkUrl.replace('{cidr}', cidr));
  }

  /**
   * Assign a given network (or ip) to a service or resource.
   * Only resourceId or serviceId is expected at once.
   * @param {string} cidr Network (or ip) identified as 'aaa.bbb.ccc.ddd/nn'.
   * @param {boolean} assigned True to create the association, false to break it.
   * @param {number?} resourceId Id of a resource to associate a network to a resource.
   * @param {number?} serviceId Id of a service to associate a network to a service.
   */
  static async assignNetwork(cidr, assigned, resourceId, serviceId, companyId) {
    if (resourceId) {
      return this.makeRequest(this.assignResourceNetworkUrl.replace('{cidr}', cidr), {
        method: 'PATCH',
        body: JSON.stringify({ physicalResource: assigned ? resourceId : null })
      });
    }
    if (serviceId) {
      return this.makeRequest(this.assignResourceNetworkUrl.replace('{cidr}', cidr), {
        method: 'PATCH',
        body: JSON.stringify({ service: assigned ? serviceId : null })
      });
    }
    if (companyId) {
      return this.makeRequest(this.assignResourceNetworkUrl.replace('{cidr}', cidr), {
        method: 'PATCH',
        body: JSON.stringify({ company: assigned ? companyId : null })
      });
    }
    return false;
  }

  /**
   * Set comment on network
   * @param {string} cidr Network (or ip) identified as 'aaa.bbb.ccc.ddd/nn'.
   * @param {string} comment The commment set on the Network.
   */
  static async setComment(cidr, comment) {
    return this.makeRequest(this.networkUrl.replace('{cidr}', cidr), {
      method: 'PATCH',
      body: JSON.stringify({ comment })
    });
  }

  static async getNetworkTypes(mode) {
    return this.makeRequest(this.networkTypesUrl.replace('{mode}', mode));
  }

  static async getNetworkGroups(networkTypeId) {
    return this.makeRequest(
      this.networkGroupsUrl.replace('{typeId}', networkTypeId)
    );
  }
}

export default NetworksApi;

/**
 * Get the related network roots to show or assign.
 * Only one of {siteId, serviceId, resourceId} should be set at once.
 * @param {number?} siteId Required if showing networks for a site.
 * @param {number?} serviceId Required if showing or assigning networks to a service.
 * @param {number?} resourceId Required if showing or assigning networks to a resource.
 * @param {boolean} isAssignMode True to get roots of all assignable networks,
 * false to get only the roots of the assigned networks.
 * @param {function} dispatch A dispatcher that accepts a 'loadNetworks' action type.
 */
export function useRelatedNetworks(networkGroupId, companyId, siteId, serviceId,
  resourceId, isAssignMode, dispatch) {
  useEffect(() => {
    NetworksApi
      .getRelatedNetworks(networkGroupId, companyId, siteId, serviceId, resourceId, isAssignMode)
      .then((data) => dispatch({ type: 'loadNetworks', payload: data }));
  }, [networkGroupId, companyId, siteId, serviceId, resourceId, isAssignMode, dispatch]);

  return [];
}

/**
 * Retrieves the detailed information for a given network (or ip).
 * A dispatcher will be invoked when the data arrives.
 * @param {string} cidr The cidr string for the network or ip.
 * @param {function} dispatch A dispatcher that accepts a 'load' action type.
 */
export function useNetwork(cidr, dispatch) {
  useEffect(() => {
    NetworksApi
      .getSubnet(cidr)
      .then((data) => dispatch({ type: 'load', payload: data }));
  }, [cidr, dispatch]);

  return [];
}
