import { renderPopoverComponent } from '../components/PopoverIcon';

$(document).ready(() => {
  const $form = $('form.ajax-form');
  const $output = $('.ajax-output');
  const url = $form.attr('action');
  const genericError = 'There has been a problem getting your data. Please try again later.';
  const sessionError = 'Your session has expired. Please login again. If your problem persists, contact support.';

  const handleErrors = (response) => {
    const statusCode = response.status;
    if (statusCode === 401 || statusCode === 403) {
      return {
        shouldReload: true,
        message: sessionError
      };
    }
    return {
      message: genericError
    };
  };

  function update() {
    let shouldReload = false;
    window.App.utils.showLoading();
    fetch(`${url}?${$form.serialize()}`)
      .then((response) => {
        if (!response.ok) {
          const errorInfo = handleErrors(response);
          shouldReload = errorInfo.shouldReload;
          throw new Error(errorInfo.message);
        }
        return response.text();
      })
      .then((html) => {
        $output.html(html);
        $output.find('.react-popover-icon').each((_, element) => {
          renderPopoverComponent(element);
        });
        window.App.utils.hideLoading();
        window.App.callbackOnAjaxLoad.forEach((callback) => {
          callback($('table.two-level-table'));
        });
      })
      .catch((error) => {
        window.alert(error.toString());
        if (shouldReload) {
          window.location.reload();
        }
      });
  }

  if ($form.length) {
    $form.find('.ajax-submit-change').click((e) => {
      e.preventDefault();
      update();
    });
    update();
  }
});
