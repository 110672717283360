import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import NetworkStatusBadge from './NetworkStatusBadge';
import OptionalCheckbox from './OptionalCheckbox';
import OptionalInput from './OptionalInput';
import NetworksApi, { useNetwork } from '../api/NetworksApi';

/**
 * Returns true if the checkbox for this network is enabled for user modification.
 * This is true if we are assigning and the network has status available or when it is
 * not available, but it is used by this same service or resource (so it can be unassigned).
 * @param {{mode, serviceStatus, physicalResourceSatus, ...}} state The component's state.
 * @param {boolean} assignable It's true when we are assigning, false for read only.
 * @param {number?} companyId The company id if state.mode is 'company'.
 * @param {number?} serviceId The service id if state.mode is 'service'.
 * @param {number?} resourceId The resource id if state.mode is 'resource'.
 */
const isEnabled = (state, assignable, companyId, serviceId, resourceId) => {
  if (!assignable) {
    return false;
  }

  if (state.mode === 'company') {
    if (state.company?.id === companyId) {
      return true;
    }
    return state.companyStatus === 'available';
  }

  if (state.mode === 'service') {
    if (state.service?.id === serviceId) {
      return true;
    }
    return state.serviceStatus === 'available';
  }

  if (state.mode === 'resource') {
    if (state.physicalResource?.id === resourceId) {
      return true;
    }
    return state.physicalResourceStatus === 'available';
  }

  return false;
};

/**
 * [Trinary logic warning!] This method returns if the checkbox for this component
 * should be shown as checked (true), unchecked (false) or indeterminate (null).
 * @param {{mode, serviceStatus, physicalResourceSatus, ...}} state The component's state.
 * @param {number?} companyId The company id if state.mode is 'company'.
 * @param {number?} serviceId The service id if state.mode is 'service'.
 * @param {number?} resourceId The resource id if state.mode is 'resource'.
 * @returns {true|false|null}
 */
const isChecked = (state, companyId, serviceId, resourceId) => {
  if (state.mode === 'company') {
    if (state.companyStatus === 'available') {
      return false;
    }
    if (state.companyStatus === 'partial') {
      return null;
    }
    if (state.companyStatus === 'unavailable') {
      if (state.company?.id === companyId) {
        return true;
      }
    }
  }

  if (state.mode === 'service') {
    if (state.serviceStatus === 'available') {
      return false;
    }
    if (state.serviceStatus === 'partial') {
      return null;
    }
    if (state.serviceStatus === 'unavailable') {
      if (state.service?.id === serviceId) {
        return true;
      }
    }
  }

  if (state.mode === 'resource') {
    if (state.physicalResourceSatus === 'available') {
      return false;
    }
    if (state.physicalResourceSatus === 'partial') {
      return null;
    }
    if (state.physicalResourceSatus === 'unavailable') {
      if (state.resource?.id === resourceId) {
        return true;
      }
    }
  }

  return null;
};

/**
 * Returns the right status based on the current mode (all, company service,
 * resource) of this component. Service status is used as default.
 * @param {object} state Component's state.
 */
const getBadgeStatus = (state) => {
  if (state.mode === 'company') {
    return state.companyStatus;
  }

  if (state.mode === 'service') {
    return state.serviceStatus;
  }
  if (state.mode === 'resource') {
    return state.physicalResourceStatus;
  }
  return state.serviceStatus;
};

/**
 * Reducer for NetworksTreeItem state.
 * @param {object} state Current state
 * @param {{type: string, payload?: object}} action Action to perform on state.
 */
const reducer = (state, action) => {
  switch (action.type) {
    // When the caret is expanded and the children netwroks are shown
    case 'expand':
      return { ...state, expanded: true };

    // When the caret is contracted and the children are hidden
    case 'collapse':
      return { ...state, expanded: false };

    // When the caret is clicked and the children visibility is toggled
    case 'toggleExpand':
      return { ...state, expanded: !state.expanded };

    // When data arrives from the API for this control, ending loading state
    case 'load':
      return {
        ...state, ...action.payload, loaded: true
      };

    // When the control starts fetching new data, entering loading state
    case 'reload':
      return { ...state, expanded: false, loaded: false };
    default: throw new Error(`Unknown action ${action.type}`);
  }
};

export default function NetworksTreeItem({
  cidr, depth, mode, assignable, companyId, serviceId, resourceId,
  showCompanyColumns, updateCallback
}) {
  const [state, dispatch] = useReducer(reducer, {
    startIp: '', // 'aaa.bbb.ccc.ddd', IPv4
    prefix: 32, // 0..32
    mode, // 'all', 'site', 'service', 'resource'
    companyStatus: '', // 'available', 'unavailable', 'partial'
    serviceStatus: '', // 'available', 'unavailable', 'partial'
    physicalResourceStatus: '', // 'available', 'unavailable', 'partial'
    subnets: [], // array of IPv4 strings
    expanded: false,
    loaded: false
  });

  // Fetch data for this network's cidr string
  useNetwork(cidr, dispatch);

  // Assign company, service or resource
  const assignNetwork = useCallback((assigned) => {
    dispatch({ type: 'reload' });
    NetworksApi
      .assignNetwork(cidr, assigned, resourceId, serviceId, companyId)
      .then((data) => {
        dispatch({ type: 'load', payload: data });
        if (updateCallback) { updateCallback(); }
      });
  }, [dispatch, cidr, resourceId, serviceId, companyId, updateCallback]);

  // set comment
  const setComment = useCallback((comment) => {
    dispatch({ type: 'reload' });
    NetworksApi
      .setComment(cidr, comment)
      .then((data) => {
        dispatch({ type: 'load', payload: data });
        if (updateCallback) { updateCallback(); }
      });
  }, [dispatch, cidr, updateCallback]);

  // React to updates in nested elements
  const childrenUpdated = useCallback(() => {
    NetworksApi
      .getSubnet(cidr)
      .then((data) => {
        dispatch({ type: 'load', payload: data });
        if (updateCallback) { updateCallback(); }
      });
  }, [dispatch, cidr, updateCallback]);

  // Check for some common programming errors
  if (assignable && !companyId && !serviceId && !resourceId) {
    // eslint-disable-next-line no-console
    console.error(
      `NetwrokTreeItem for ${cidr} is in assign mode, but neither the companyId nor the serviceId nor the resourceId are defined`
    );
  }

  return (
    <>
      <tr className="tree-row">
        <td className="tree-node-name" onClick={() => dispatch({ type: 'toggleExpand' })}>
          <span className="spacer" style={{ width: `${18 * depth}px` }} />
          { state.subnets && state.subnets.length > 0
            ? <i className={`fa fa-caret-${state.expanded ? 'down' : 'right'}`} />
            : <i className="fa" /> /* keep the <i> for alignment */ }
          { state.loaded && state.startIp.length > 6 /* 'a.b.c.d' */
            ? `${state.startIp}/${state.prefix}`
            : (
              <>
                {cidr}
                {' '}
                <i className="fas fa-circle-notch fa-spin" />
              </>
            )}
        </td>
        {(mode !== 'company' || showCompanyColumns) && (
          <td>
            <NetworkStatusBadge status={getBadgeStatus(state)} />
          </td>
        )}
        {showCompanyColumns && (
          <td>
            {state.company && (
              <a
                href={`/companies/${state.company.id}/`}
                target="_blank"
                rel="noreferrer"
              >
                {state.company.name}
              </a>
            )}
          </td>
        )}
        <td>
          {state.service && (
            <a
              href={`/services/${state.service.id}/`}
              target="_blank"
              rel="noreferrer"
            >
              {state.service.intuitiveDescription}
            </a>
          )}
        </td>
        <td>
          {state.physicalResource && (
            <a
              href={`/resources/physical/${state.physicalResource.id}/`}
              target="_blank"
              rel="noreferrer"
            >
              {state.physicalResource.name}
            </a>
          )}
        </td>
        <td>
          { assignable && state.startIp && (
          <OptionalInput
            initialValue={state.comment}
            disabled={!(state.service || state.physicalResource)}
            callback={setComment}
          />
          ) }
          { !assignable && state.comment }
        </td>
        { assignable && (
        <td>
          <OptionalCheckbox
            disabled={!isEnabled(state, assignable, companyId, serviceId, resourceId)}
            checked={isChecked(state, companyId, serviceId, resourceId)}
            callback={assignNetwork}
          />
        </td>
        ) }
      </tr>
      { state.expanded && state.subnets.map((cidrSubnet) => (
        <NetworksTreeItem
          cidr={cidrSubnet}
          depth={depth + 1}
          key={cidrSubnet}
          mode={mode}
          assignable={assignable}
          resourceId={resourceId}
          serviceId={serviceId}
          companyId={companyId}
          showCompanyColumns={showCompanyColumns}
          updateCallback={childrenUpdated}
        />
      )) }
    </>
  );
}

NetworksTreeItem.propTypes = {
  cidr: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  assignable: PropTypes.bool,
  serviceId: PropTypes.number,
  companyId: PropTypes.number,
  resourceId: PropTypes.number,
  showCompanyColumns: PropTypes.bool,
  updateCallback: PropTypes.func
};

NetworksTreeItem.defaultProps = {
  assignable: false,
  serviceId: null,
  companyId: null,
  resourceId: null,
  showCompanyColumns: false,
  updateCallback: null
};
