import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * A checkbox that can be set to indeterminate state by nullifying the 'checked' prop.
 */
function OptionalCheckbox({
  checked, disabled, callback
}) {
  const checkRef = useRef();

  useEffect(() => {
    checkRef.current.checked = !!checked;
    checkRef.current.indeterminate = checked === null;
  }, [checked]);

  return (
    <input
      type="checkbox"
      ref={checkRef}
      disabled={disabled}
      onChange={() => { callback(checkRef.current.checked); }}
    />
  );
}

export default React.memo(OptionalCheckbox);

OptionalCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired
};

OptionalCheckbox.defaultProps = {
  checked: null,
  disabled: false
};
