import { useCallback, useEffect, useState } from 'react';
import { secondsToMilliseconds } from '../../../utils/timers';
import { checkSessionTimestamp, getRefreshSession, calculateRemainingTimeMilliSeconds } from '../api/refreshModalApi';
import {
  MODAL_COUNTDOWN_SECONDS,
  PUBLIC_PATHNAMES
} from '../configs/refreshSessionModalConfig';

const useRefreshSessionModal = () => {
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [sessionTimestamp, setSessionTimestamp] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timerCountdown, setTimerCountdown] = useState(MODAL_COUNTDOWN_SECONDS);

  const isPublicPathname = () => PUBLIC_PATHNAMES.some(
    (pathname) => pathname === window.location.pathname
  );

  const getCachedTimestamp = () => window.localStorage.getItem('session-timestamp');

  // Requests next session expire timestamp and saves it in session storage
  const cacheTimestamp = async () => {
    const nextTimestamp = await checkSessionTimestamp();
    setSessionTimestamp(nextTimestamp);
    window.localStorage.setItem('session-timestamp', nextTimestamp);
  };

  /**
   * Closes refresh modal and resets its coundown timer.
   * Based in options, can handle expire session request too.
   * @param {{ expireSession: boolean }} options Extra options for handle modal closing
   */
  const closeModal = ({ expireSession } = {}) => {
    setShowModal(false);
    setTimerCountdown(MODAL_COUNTDOWN_SECONDS);

    // If must expire session, after 1 second redirect to login page
    if (expireSession) {
      setTimeout(() => {
        window.location.reload();
      }, secondsToMilliseconds(3));
    }
  };

  // Refreshes session, generates new timeout for modal and caches last timestamp
  // Finally returns if session is valid
  const handleRefreshSession = useCallback(async () => {
    const validSession = await getRefreshSession();
    cacheTimestamp();

    return Boolean(validSession);
  }, []);

  /**
   * Checks if current session keeps being valid checking local tab timestamp
   * Basically, each app tab in browsers caches its own expiration session timestamp.
   *
   * When modal reaches 0 seconds in timeout, this expiration timestamp is compared within
   * session timestamp value provided from check session endpoint
   *
   * If timestamps are different means that user has had activity in other tab and closes modal
   * without invalidate session.
   *
   * If timestamps are same, means that user has not had activity in any tab and invalidates session
   */
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleModal = async () => {
      if (timerCountdown === 0) {
        const cachedTimestamp = Number(getCachedTimestamp());
        const nextTimestamp = Number(await checkSessionTimestamp());

        // Sometimes, timestamp provided by endpoint has some minimum drift which is controlled with
        // these possible drift times array
        const possibleDriftTimes = [
          nextTimestamp - 2, nextTimestamp - 1, nextTimestamp, nextTimestamp + 1, nextTimestamp + 2
        ];
        const expireSession = possibleDriftTimes
          .some((time) => time === cachedTimestamp);

        if (!expireSession) {
          cacheTimestamp();
        }

        closeModal({ expireSession });
      }
    };

    if (showModal) handleModal();
  }, [showModal, timerCountdown]);

  // Handles modal counter timedown each second
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showModal) {
      const interval = setInterval(() => {
        if (timerCountdown > 0) {
          setTimerCountdown(timerCountdown - 1);
        }
      }, secondsToMilliseconds(1));

      return () => {
        clearInterval(interval);
      };
    }
  }, [timerCountdown, showModal]);

  // Initializes first expiration timeout
  useEffect(() => {
    cacheTimestamp();
  }, []); // eslint-disable-line

  useEffect(() => {
    function handleStorageChange() {
      const sessionTimeStampStorage = window.localStorage.getItem('session-timestamp');

      if (sessionTimeStampStorage !== sessionTimestamp && sessionTimeStampStorage !== 'undefined') {
        setSessionTimestamp(sessionTimeStampStorage);
      }
    }

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };

  }, []); // eslint-disable-line

  useEffect(() => {
    setShowModal(false);
    setTimerCountdown(MODAL_COUNTDOWN_SECONDS);
    if (!isPublicPathname() && sessionTimestamp !== null) {
      clearTimeout(currentTimeout);

      const timeRemaining = calculateRemainingTimeMilliSeconds(sessionTimestamp);

      if (Number.isNaN(Number(timeRemaining))) {
        window.location.reload();
      }
      const timeout = setTimeout(() => {
        setShowModal(true);
      }, timeRemaining - ((MODAL_COUNTDOWN_SECONDS) * 1000));

      setCurrentTimeout(timeout);
    }
  }, [sessionTimestamp]); // eslint-disable-line

  return {
    values: {
      showModal,
      timerCountdown
    },

    handlers: {
      handleRefreshSession,
      closeModal
    }
  };
};

export { useRefreshSessionModal };
