import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useRefreshSessionModal } from './hooks/useRefreshSessionModal';

export default function RefreshSessionModal() {
  const {
    values: { showModal, timerCountdown },
    handlers: { handleRefreshSession, closeModal }
  } = useRefreshSessionModal();

  const remainingTime = gettext('Your session expires in %s s');
  const handleClick = async () => {
    const validSession = await handleRefreshSession();
    closeModal({ expireSession: !validSession });
  };

  return (
    <Modal show={showModal}>
      <Modal.Header>
        <h4 className="mb-0 mx-auto">
          {interpolate(remainingTime, [timerCountdown])}
        </h4>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center  gap-3">
        <strong className="d-inline-block fs-normal">{gettext('We have detected inactivity in the session')}</strong>
        <span className="d-inline-block fst-italic">{gettext('Do you want to continue browsing?')}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" onClick={handleClick} variant="primary" className="mx-auto">
          {gettext('Keep session open')}
        </Button>
      </Modal.Footer>
    </Modal>

  );
}
