import React from 'react';
import ReactDOM from 'react-dom';
import RefreshSessionModal from './RefreshSessionModal';

const element = document.querySelector('.refresh-session-modal');

if (element) {
  ReactDOM.render(
    React.createElement(RefreshSessionModal, {}), element
  );
}
