const calculateRemainingTimeMilliSeconds = (sessionTimeStamp) => {
  const now = new Date();
  const limitTime = new Date(sessionTimeStamp * 1000);
  const remainingTime = (limitTime - now) / 1000;
  return remainingTime * 1000;
};

/**
 * Refresh current user session
 * @returns {Promise<number>} refreshed expiration timestamp
 */
const getRefreshSession = async () => {
  const REFRESH_SESSION_ENDPOINT = '/api/v1/session-refresh';
  const options = {
    'Content-Type': 'application/json'
  };

  const rawResponse = await fetch(REFRESH_SESSION_ENDPOINT, options);

  if (!rawResponse.ok) return null;

  const data = await rawResponse.json();

  return data;
};

const checkSessionTimestamp = async () => {
  const CHECK_SESSION_TIMESTAMP_ENDPOINT = '/api/v1/session-check';
  const options = {
    'Content-Type': 'application/json'
  };

  const rawResponse = await fetch(CHECK_SESSION_TIMESTAMP_ENDPOINT, options);

  if (rawResponse.status === 403 && window.localStorage.getItem('session-timestamp') !== 'null') {
    window.location.reload();
  }

  if (!rawResponse.ok) return null;

  const data = await rawResponse.json();

  return data;
};

export { getRefreshSession, checkSessionTimestamp, calculateRemainingTimeMilliSeconds };
