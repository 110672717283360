const escapeHtml = (unsafe) => unsafe.replaceAll('&amp;', '&').replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&quot;', '"')
  .replaceAll('&#039;', "'");

window.addEventListener('load', () => {
  const richTextContainers = document.getElementsByClassName('rich-text-container');
  Array.from(richTextContainers).forEach((container) => {
    // eslint-disable-next-line no-param-reassign
    container.innerHTML = escapeHtml(container.innerHTML);
  });
});
