import { getCookie } from '../components/TableView/api/ViewsApi';

async function selectCheckboxRequest(endpoint, formData) {
  try {
    const REQUEST_OPTIONS = {
      method: 'POST',
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'same-origin',
      body: formData
    };

    await fetch(endpoint, REQUEST_OPTIONS);
  } catch (err) {
    console.error(err);
    window.alert('Error');
  }
}

async function handleCheckboxClick(event, endpoint) {
  const { checked } = event.target;
  const formData = new URLSearchParams();

  formData.append('check', checked);

  selectCheckboxRequest(endpoint, formData);
}

function initializeCheckboxes() {
  const technicianForms = document.querySelectorAll('.technician-form');
  const engineerForms = document.querySelectorAll('.engineer-form');
  const allCheckboxesForms = [...technicianForms, ...engineerForms];

  if (!allCheckboxesForms.length) {
    return;
  }

  allCheckboxesForms.forEach((form) => {
    const checkboxElement = form.querySelector('input[type=checkbox]');
    const endpoint = form.getAttribute('action');

    checkboxElement.addEventListener('click', (event) => handleCheckboxClick(event, endpoint));
  });
}

initializeCheckboxes();
