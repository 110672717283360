$(() => {
  $(document).ready(() => {
    $('.pdf-generate-link').click((e) => {
      const confirmed = window.confirm('¿Seguro que quieres generar este PDF?');

      if (confirmed) {
        $(e.target).hide();
      }

      return confirmed;
    });
  });
});
