$(document).ready(() => {
  const $tables = $('table.active-table');
  $tables.each((_, table) => {
    const order = table.getAttribute('order');
    const datatable = $(table).DataTable({
      ...(order && {
        // eslint-disable-next-line no-eval
        order: eval(order) ?? []
      }),
      lengthMenu: [[-1, 10, 25, 50], ['Todos', 10, 25, 50]],
      language: {
        url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Spanish.json'
      }
    });

    datatable.columns.adjust().draw();
  });
});
