$(() => {
  // eslint-disable-next-line func-names
  $('.collapsable-nav').each(function () {
    const $collapsableNav = $(this);
    const dataTarget = $collapsableNav.attr('data-target');
    const $collapsable = $(`${dataTarget}.collapse`);

    const isExpandedCache = window.sessionStorage.getItem(dataTarget) === 'true';

    if (isExpandedCache) {
      $collapsable.collapse('show');
    }

    // Save collapsable state in local storage
    $collapsableNav.on('click', () => {
      const isCurrentExpanded = !($collapsableNav.attr('aria-expanded') === 'true');

      if (isCurrentExpanded) {
        window.sessionStorage.setItem(dataTarget, 'true');
        return;
      }

      window.sessionStorage.removeItem(dataTarget);
    });
  });
});
