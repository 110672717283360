import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import NetworksApi from '../api/NetworksApi';
import PopoverIcon from '../../PopoverIcon/PopoverIconComponent';

function NetworkFilters({ onChange, mode }) {
  const [networkTypes, setNetworkTypes] = useState([]);
  const [networkGroups, setNetworkGroups] = useState([]);
  const [state, setState] = useState({
    currentNetworkType: '',
    currentNetworkGroup: ''
  });

  const loadGroupsFor = useCallback((networkTypeId) => {
    NetworksApi.getNetworkGroups(networkTypeId)
      .then((nGroups) => {
        setNetworkGroups(nGroups);
        const newState = {
          currentNetworkType: Number(networkTypeId),
          currentNetworkGroup: nGroups[0].id
        };
        setState(newState);
        onChange(newState);
      });
  }, [onChange]);

  // When the first selector (network type) is changed
  const handleNetworkTypeChange = (id) => {
    const newState = {
      currentNetworkType: Number(id),
      currentNetworkGroup: ''
    };
    setState(newState);
    // Changing the network type requires reloading the list
    // of network groups for the new type...
    loadGroupsFor(id);
    // ...so we don't notify onChange() groups are loaded.
  };

  // When the second selector (network group) is changed
  const handleNetworkGroupChange = (id) => {
    const newState = {
      ...state,
      currentNetworkGroup: Number(id)
    };
    setState(newState);
    onChange(newState);
  };

  // Initial load of network types
  useEffect(() => {
    NetworksApi.getNetworkTypes(mode)
      .then((nTypes) => {
        setNetworkTypes(nTypes);
        loadGroupsFor(nTypes[0].id);
      });
  }, [onChange, loadGroupsFor, mode]);

  return (
    <div className="row">
      <div className="col-12 col-sm-6">
        Tipo IP
        <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('Filter IP type')} />
        <br />
        <select className="form-control js-not-select2" value={state.currentNetworkType} onChange={(e) => handleNetworkTypeChange(e.target.value)}>
          {networkTypes.map((networkType) => (
            <option value={networkType.id} key={networkType.id}>{networkType.name}</option>
          ))}
        </select>
      </div>
      <div className="col-12 col-sm-6">
        Subgrupos IP
        <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('Filter IP sub-group')} />
        <br />
        <select className="form-control js-not-select2" value={state.currentNetworkGroup} onChange={(e) => handleNetworkGroupChange(e.target.value)}>
          {networkGroups.map((networkGroup) => (
            <option value={networkGroup.id} key={networkGroup.id}>{networkGroup.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default NetworkFilters;

NetworkFilters.propTypes = {
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
