import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

/**
 * A input that can be set to indeterminate state by nullifying the 'value' prop.
 */
function OptionalInput({
  initialValue, disabled, callback
}) {
  const checkRef = useRef();
  const [value, setValue] = useState(initialValue);

  const debounced = useRef(debounce((newValue) => callback(newValue), 1000));

  const onChange = (event) => {
    setValue(event.target.value);
    debounced.current(event.target.value);
  };

  useEffect(() => {
    checkRef.current.value = value;
    checkRef.current.indeterminate = value === null;
  }, [value]);

  return (
    <input
      type="text"
      ref={checkRef}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  );
}

export default React.memo(OptionalInput);

OptionalInput.propTypes = {
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired
};

OptionalInput.defaultProps = {
  initialValue: '',
  disabled: false
};
